import axios from "axios";
import Cookies from "js-cookie";
import {useHistory} from 'react-router-dom';

const version = '0.1';
const urlAuthentication = ['Logout', 'History', 'Company', 'ControlUnit', 'SenseUnit', 'Vehicle', 'Parts'];

// api endpoint for CosmosDB used for CosmosDB testing
// https://ime-we-p-azapp-solarontop-api002-ime-we-a-azapp-sot-api.azurewebsites.net

const baseUrlState = (state) => {
    switch (state) {
        case 'staging':
            return `https://ime-we-p-azapp-solarontop-api002.azurewebsites.net/api/v${version}/`;
        case 'prod':
            return `https://ime-we-p-azapp-solarontop-api002.azurewebsites.net/api/v${version}/`;
        case 'dev':
            return `http://localhost:8080/https://ime-we-p-azapp-solarontop-api002.azurewebsites.net/api/v${version}/`;
        default:
            return `https://ime-we-p-azapp-solarontop-api002.azurewebsites.net/api/v${version}/`;
    }
};

const apiClient = axios.create({
    // baseURL: API_URL, // <- ENV variable
    baseURL: baseUrlState('prod')
});

apiClient.interceptors.request.use((config) => {

        const urlCheck = urlAuthentication.some(x => config.url.includes(x));

        if (urlCheck || (config.url.includes('Users') && Cookies.get('authToken'))) {
            const token = Cookies.get('authToken');
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return ({
            ...config,
            headers: {
                ...config.headers,
                'content-type': 'application/json',
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': "GET, POST, OPTIONS",
            },
            params: {...config.params, timestamp: Date.now()},
        })
    },
    error => Promise.reject(error),
);

apiClient.interceptors.response.use((response) =>
        response,
    async (error) => {
        if (error.response.status === 401) {
            const history = useHistory();
            // Unauthorized response
            // Redirect to the login page or any other route
            Cookies.remove('authToken');
            history.push("/auth/login");
        }

        return Promise.reject(error.response.data);
    },
);

const {get, post, put, delete: destroy} = apiClient;
export {get, post, put, destroy};